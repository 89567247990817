@if (popup) {
  <app-popup-header
    [title]="popup.settings.customValues?.title ?? ''"
    [closeable]="popup.settings.closeable"
    (closePopup)="onPopupClose()"
  ></app-popup-header>

  <!-- Message -->
  <p [innerHTML]="popup.settings.customValues?.message"></p>

  <!-- Buttons -->
  <div class="footer row">
    <div class="col-12 px-0 text-right">
      <p class="mb-0">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onOkButton()"
        >OK</button>
      </p>
    </div>
  </div>
}
