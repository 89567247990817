import { Component } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

import { IPopup, PopupService } from '@dep/frontend/services/popup.service';

@Component({
  selector: 'app-popup-generic-message',
  templateUrl: './generic-message.component.html',
  styleUrls: ['./generic-message.component.scss'],
})
export class PopupGenericMessageComponent {
  /** Popup information, automatically set by `PopupBaseComponent`. */
  public popup?: IPopup;

  constructor(
    private logger: NGXLogger,
    private popupService: PopupService,
  ) {
    this.logger.debug('Generic message popup initialized');
  }

  /**
   * Called when OK button is clicked.
   *
   * Calls the `okButton` callback and closes the popup.
   */
  public onOkButton(): void {
    if (this.popup) {
      if (this.popup.callbacks.okButton) {
        this.popup.callbacks.okButton();
      }
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }

  /**
   * Close the popup.
   */
  public onPopupClose(): void {
    if (this.popup) {
      this.popupService.close(this.popup.uuid);
    } else {
      this.logger.error('Could not close popup because "popup" is not set', this.popup);
    }
  }
}
